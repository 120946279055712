import React from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import BannerStyles from '../styles/BannerStyles';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import { RouteComponentProps } from '@reach/router';
import BlogLayout from '../components/BlogLayout';
import SEO from '../components/SEO';
import Sidebar from '../components/Sidebar';
import { prepareHtml } from './blogPostPage';
import injectBanner from '../utils/injectBanner';
import banner from '../images/banner-01.png';
import { ContentfulBlogPost } from '../graphql-types';

interface OwnProps {
  data: {
    category: import('../graphql-types').ContentfulCategory;
    sidebar: import('../graphql-types').ContentfulSidebarConnection;
  };
  pageContext: {
    locale: string;
    posts: (ContentfulBlogPost & { url: string })[];
    categories: { slug: string; title: string }[];
  };
}

type Props = OwnProps & WrappedComponentProps & RouteComponentProps;

class CategoryTemplate extends React.Component<Props> {
  singlePost = (post: ContentfulBlogPost & { url: string }, index: number) => {
    return (
      <div className="symptom" key={index}>
        <span>{post.category.title}</span>
        <div
          className="title"
          style={{ textDecorationLine: 'none', color: '#535d7e' }}
        >
          <Link to={post.url}>{post.title}</Link>
        </div>
      </div>
    );
  };

  injectBanner = (html: string) => {
    return html.replace(
      /<banner>/,
      injectBanner({
        title: this.props.intl.formatMessage({
          id: 'articles.banner.title',
          defaultMessage: 'CARA CARE supports you with your digestive problems',
        }),
        cta: this.props.intl.formatMessage({
          id: 'articles.banner.cta',
          defaultMessage: 'Get the App',
        }),
        link: this.props.intl.formatMessage({
          id: 'articles.banner.link',
          defaultMessage: 'https://cara.app.link/QyGYmQzxD4',
        }),
        banner,
      })
    );
  };

  render() {
    const { sidebar, category } = this.props.data;
    const { categories: breadCrumbCategories, locale } = this.props.pageContext;

    return (
      <BlogLayout>
        <BannerStyles />
        <SEO
          title={category.title}
          description={category.title}
          lang={locale}
          noindex={
            this.props.location.pathname === '/de/diagnose/magenspiegelung/'
          }
        />
        <div className="wrapper">
          <div className="header article">
            {breadCrumbCategories.map((el, index) => {
              if (index === breadCrumbCategories.length - 1) {
                return null;
              }
              if (index !== breadCrumbCategories.length - 2) {
                return (
                  <span>
                    <Link key={index} to={el.slug}>
                      {el.title}
                    </Link>{' '}
                    &gt;{' '}
                  </span>
                );
              } else {
                return (
                  <Link key={index} to={el.slug}>
                    {el.title}
                  </Link>
                );
              }
            })}

            <h1>
              {breadCrumbCategories[breadCrumbCategories.length - 1].title}
            </h1>
          </div>
          <div className="main article">
            <div className="content">
              <div className="cover">
                {category?.heroImage && (
                  <Image
                    fluid={category?.heroImage?.fluid}
                    alt={category?.heroImage?.title || category.title}
                  />
                )}
              </div>
              {category?.body?.childMarkdownRemark?.html && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: prepareHtml(
                      category.body.childMarkdownRemark.html,
                      this.props.intl
                    ),
                  }}
                />
              )}
              <div className="symptom-wrapper">
                <h3>
                  <FormattedMessage
                    id="blog.mostReadArticlesAbout"
                    defaultMessage="Most read articles about: {category}"
                    values={{ category: category.title }}
                  />
                </h3>
                {this.props.pageContext?.posts
                  ?.filter((post) => post.isLocalized)
                  .map(this.singlePost)}
              </div>
            </div>
            {sidebar?.nodes?.length && (
              <Sidebar
                sidebar={sidebar.nodes[0]}
                locale={this.props.pageContext.locale}
              />
            )}
          </div>
        </div>
      </BlogLayout>
    );
  }
}

export const pageQuery = graphql`
  query ContentfulBlogPostByCategory($slug: String!, $locale: String!) {
    category: contentfulCategory(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      id
      title
      heroImage {
        description
        fluid(maxWidth: 900) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    sidebar: allContentfulSidebar(
      filter: { node_locale: { eq: $locale } }
      limit: 1
    ) {
      nodes {
        buttonText
        content {
          childMarkdownRemark {
            html
          }
        }
        topImage {
          fluid(maxWidth: 715) {
            ...GatsbyContentfulFluid
          }
        }
        bottomImage {
          fluid(maxWidth: 715) {
            ...GatsbyContentfulFluid
          }
        }
        bottomImageLink
        buttonLink
      }
    }
  }
`;

export default injectIntl(CategoryTemplate);
